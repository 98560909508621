import React, { useEffect } from 'react';
import styled from 'styled-components' // TODO: Delete if unused

const TickerStreamContainer = styled.div`

        margin: auto;

        body {
            font-family: 'LcdSolid', monospace;
        }

        position: absolute;
        
        @font-face {
            font-family: 'LcdSolid';
            src: url('./fonts/LcdSolid.ttf') format('truetype');
        }

        .ticker {
            /* width: fit-content; */
            overflow: hidden;
            font-family: 'LcdSolid', monospace;
            margin-bottom: 4px;
            width: fit-content;
        }

        .ticker.positive {
            color: #00b500;
        }

        .ticker.negative {
            /* color: #c70101; */
            color: rgb(242 2 2 / 81%);
            /* color: rgb(242 2 2); */
        }

        .ticker div {
            white-space: nowrap;
        }

        /* .ticker.positive div {
            animation: ticker-positive 180s linear infinite;
            position: relative;
        }

        .ticker.negative div {
            animation: ticker-negative 180s linear infinite;
            position: relative;
        } */
`;

function TickerStream() {
    function splitIntoChunks(array, chunkSize) {
        var chunks = [];
        for (var i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    const SPEED_CONSTANT = 25;

    function animateTicker(element, positive = true, elementId) {
      element.id = "ticker" + elementId;
      const width = (element.scrollWidth / 3);
    
      let duration = width / SPEED_CONSTANT;

      const style = document.createElement('style');
    
      element.style.position = 'relative';
      let animationName = "ticker" + elementId;
      style.innerHTML = `

        @keyframes ${animationName} {
          0% {
            transform: translateX(${positive ? (0) : -(element.scrollWidth / 3)}px);
          }
          100% {
            transform: translateX(${positive ? -(element.scrollWidth / 3) : (0)}px);
          }
        }
      `;
    
      document.head.appendChild(style);
      element.style.animation = `${animationName} ${duration}s linear infinite`;
    }
    
  const fetchAndDisplayData = () => {
    console.log("Fetching new ticker data for the hero component")
    fetch('https://feed.tkn.xyz/prices/page/all')
        .then(response => response.json())
        .then(data => {
            var positiveTickerElements = document.getElementsByClassName("positive-ticker");
            var negativeTickerElements = document.getElementsByClassName("negative-ticker");
            var positiveTickers = []
            var negativeTickers = []
            Object.keys(data).forEach(function (ticker) {
                let parsedTickerData = data[ticker];
                if (parsedTickerData.PRICE) {
                    var tickerPrice = data[ticker] ? parsedTickerData.PRICE.replace(' ', '') : 'N/A';
                    var change = data[ticker] ? data[ticker].CHANGEPCT24HOUR : 0;
                    if (!change.includes('-')) {
                        change = "+" + change
                    }
                    var tickerData = ticker + ": " + tickerPrice + " " + change + "% ";
                    if (change >= 0) {
                        positiveTickers.push(tickerData);
                    } else {
                        negativeTickers.push(tickerData);
                    }
                } else {
                    console.log("No price data for " + ticker)
                }
            });
            let positiveBatchSize = Math.ceil(positiveTickers.length / positiveTickerElements.length);
            let negativeBatchSize = Math.ceil(negativeTickers.length / negativeTickerElements.length);

            let positiveTickerChunks = splitIntoChunks(positiveTickers, positiveBatchSize);
            let negativeTickerChunks = splitIntoChunks(negativeTickers, negativeBatchSize);

            let tickerCount = 0;
            let posTapeCount = 0;
            for (const positiveTickerTape of positiveTickerElements) {
                let positiveTickerTapeString = positiveTickerChunks[posTapeCount].join(' ');

                positiveTickerTape.innerHTML = positiveTickerTapeString + " " + positiveTickerTapeString + " " + positiveTickerTapeString;

                animateTicker(positiveTickerTape, true, tickerCount);


                posTapeCount++;
                tickerCount++;
            }
            let negTapeCount = 0;
            for (const negativeTickerTape of negativeTickerElements) {
                let negativeTickerTapeString = negativeTickerChunks[negTapeCount].join(' ');

                negativeTickerTape.innerHTML = negativeTickerTapeString + " " + negativeTickerTapeString + " " + negativeTickerTapeString;
            
                animateTicker(negativeTickerTape, false, tickerCount);

                negTapeCount++;
                tickerCount++;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
  }

  useEffect(() => {
    fetchAndDisplayData();
    const interval = setInterval(fetchAndDisplayData, 5 * 60 * 1000);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <TickerStreamContainer style={{ opacity: '0.6' }}>
        <div className="ticker positive">
            <div className="positive-ticker"></div>
        </div>
        <div className="ticker negative">
            <div className="negative-ticker"></div>
        </div>
        <div className="ticker positive">
            <div className="positive-ticker"></div>
        </div>
        <div className="ticker negative">
            <div className="negative-ticker"></div>
        </div>
        <div className="ticker positive">
            <div className="positive-ticker"></div>
        </div>
        <div className="ticker negative">
            <div className="negative-ticker"></div>
        </div>
        <div className="ticker positive">
            <div className="positive-ticker"></div>
        </div>
        {/* <div className="ticker negative">
            <div  className="negative-ticker"></div>
        </div> */}
      {/* Add more tickers if needed */}
    </TickerStreamContainer>
  );
}

export default TickerStream;