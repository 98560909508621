import { createPublicClient, fallback } from 'viem';
import { arbitrum, avalanche, base, blast, bsc, celo, fantom, gnosis, linea, mainnet, mantle, mode, optimism, polygon, polygonZkEvm, scroll, } from 'wagmi/chains';


import { http } from 'wagmi';
import { GROVE_KEY, WISE_ABI } from './constants';

// Log descriptive errors if grove_key and wise_abi are not set
if (!GROVE_KEY) {
    console.error("Grove RPC API key is not set");
}
if (!WISE_ABI) {
    console.error("Wise ABI is not set");
}

function getChainClient(chain) {
    try {
      const client = createPublicClient({
        chain,
        transport: transports[chain.id]
      });
      return client
    }
    catch (error) {
    }
  }

  const transports = {
    [avalanche.id]: fallback([http(`https://avax-mainnet.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [arbitrum.id]: fallback([http(`https://arbitrum-one.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [base.id]: fallback([http(`https://base-mainnet.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [blast.id]: fallback([http(`https://blast-archival.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [bsc.id]: fallback([http(`https://bsc-mainnet.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [celo.id]: fallback([http(`https://celo-mainnet.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [fantom.id]: fallback([http(`https://fantom-mainnet.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [gnosis.id]: fallback([http(`https://gnosischain-mainnet.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [linea.id]: fallback([http(`https://linea.decubate.com`), http()]),
    [mainnet.id]: fallback([http(`https://eth-mainnet.rpc.grove.city/v1/${GROVE_KEY}`)]),
    [mantle.id]: fallback([http(`https://rpc.mantle.xyz`), http(`https://mantle.drpc.org`), http()]),
    [mode.id]: fallback([http(`https://mainnet.mode.network/`), http()]),
    [optimism.id]: fallback([http(`https://optimism-mainnet.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [polygon.id]: fallback([http(`https://poly-mainnet.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [polygonZkEvm.id]: fallback([http(`https://polygon-zkevm-mainnet.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
    [scroll.id]: fallback([http(`https://scroll-mainnet.rpc.grove.city/v1/${GROVE_KEY}`), http()]),
  };

  function getWiseAddressByChainId(chainId) {
    switch (chainId) {
      case mode.id:
        return "0x51d61464F60e34a429281679Ef05f554CFefD4b8";
      case blast.id:
        return "0xc4bc381C63D80CF8167AD719F76A14CEA62292Fc";
      case polygon.id:
        return "0xAA002d5Da30347076Db22786eD9b99F50B359494";
      case bsc.id:
        return "0x0019E4b60AfDc742836E2fb3ae8Eb172e9e1501C";
      case avalanche.id:
        return "0xc4bc381C63D80CF8167AD719F76A14CEA62292Fc";
      case arbitrum.id:
        return "0xb6f7FA5cF87B5daae7f90f62109578fBbf38ac08";
      case celo.id:
        return "0x84D33A8fB1e6635B45B7b851006885666271255B";
      case linea.id:
        return "0x84D33A8fB1e6635B45B7b851006885666271255B";
      case gnosis.id:
        return "0x84D33A8fB1e6635B45B7b851006885666271255B";
      case polygonZkEvm.id:
        return "0x84D33A8fB1e6635B45B7b851006885666271255B";
      case scroll.id:
        return "0x84D33A8fB1e6635B45B7b851006885666271255B";
      case fantom.id:
        return "0x0019E4b60AfDc742836E2fb3ae8Eb172e9e1501C";
      case mantle.id:
        return "0x0019E4b60AfDc742836E2fb3ae8Eb172e9e1501C";
      case mainnet.id:
        return "0xF8cC02f91035D01A519FF4CaC3EB993E3267F030";
      case optimism.id:
        return "0xc4bc381c63d80cf8167ad719f76a14cea62292fc";
      case base.id:
        return '0xDF659ED58A61ee7178943d954eC48f35B15D917a';
      default:
        return '';
    }
  }
  
  function getWiseAbiByChainId(chainId) {
    switch (chainId) {
      default:
        return WISE_ABI
    }
  }

  export {getChainClient, getWiseAddressByChainId ,getWiseAbiByChainId, transports}