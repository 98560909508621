import {ethers} from 'https://cdnjs.cloudflare.com/ajax/libs/ethers/6.10.0/ethers.js';
import {Record, Profile} from 'https://cdn.jsdelivr.net/npm/@resolverworks/enson@0.0.12/dist/index.min.js';

export async function getTickerData(tick) {
    let provider = new ethers.CloudflareProvider();
    let resolver = new ethers.Contract('0xe121A6e3a50008EFE9C617214320c2f9fF903411', [
        'function resolve(bytes name, bytes call) external view returns (bytes memory)',
    ], provider);

    let iface = new ethers.Interface([
        'function multicall(bytes[] memory calls) returns (bytes[] memory)'
    ]);
    let profile = new Profile();
    profile.setText([
        'name', 'avatar', 'url', 'description', 'decimals',
        'twitter', 'github', 'version', 'notice',
        'chainId', 'coinType', 'symbol', 'dweb',
    ]);
    profile.setCoin([
        'eth', 'op', 'arb1', 'avax', 'bnb', 'cro', 'gno', 'matic',
        'near', 'sol', 'trx', 'zil', 'ftm', 'base'
    ]);
    profile.setCoin(0x7f55c959); // sepolia
    profile.setCoin(0xa7bc86aa); // degen
    profile.chash = true;
	const t0 = Date.now();
	let pre = document.createElement('pre');
	try {
		let name = ethers.ensNormalize(`${tick ? tick + '.' : ''}tkn.eth`);
		let calls = profile.makeCallsForName(name);
		let multi = iface.encodeFunctionData('multicall', [calls]);
		let res = await resolver.resolve(ethers.dnsEncode(name, 255), multi,  {enableCcipRead: true});
		let [answers] = iface.decodeFunctionResult('multicall', res);
		let record = new Record();
		record.parseCalls(calls, answers);
		return {
			tick, 
			name,
			dur: Date.now() - t0, 
			record: record.toJSON(true)
		};
	} catch (err) {
		pre.classList.add('error');
		pre.innerText = JSON.stringify({tick, ...err}, null, '  ');
	}
	document.body.append(pre);
}
